import DefaultHelmet from "../seo/DefaultHelmet";
import PageHeader from "../components/PageHeader";
import DefaultLayout from "../layouts/DefaultLayout";
import React from "react";

const ReleaseNotes = ({ pageContext }) => {
    const { releaseNotes } = pageContext;
    const { releases } = releaseNotes.release_notes;
    const pageTitle = releaseNotes?.title || "Release Notes";
    const pageDescription = releaseNotes?.description || "Releases description";

    return (
        <DefaultLayout isHeaderWithBlackText={true}>
            <DefaultHelmet title={pageTitle} description={pageDescription}/>

            {releaseNotes.page_header && releaseNotes.page_header.title && <PageHeader {...releaseNotes.page_header} />}

            {releases && releases.length
                ? (
                    <div className="release-notes">
                        {releases
                            .sort((a, b) => {
                                const [aDay, aMonth, aYear] = a.date.split("/");
                                const [bDay, bMonth, bYear] = b.date.split("/");

                                return (new Date(`${bYear}-${bMonth}-${bDay}`) - new Date(`${aYear}-${aMonth}-${aDay}`))
                            })
                            .map(release => {
                                const [day, month, year] = release.date.split("/");

                                return (
                                    <div className="release-notes__release">
                                        <div className="release-notes__meta">
                                            <h2 className="release-notes__version">
                                                {release.version}
                                            </h2>

                                            <p className="release-notes__date">
                                                {new Date(`${year}-${month}-${day}`).toLocaleDateString("en-US", { month: "long", year: "numeric", day: "numeric" })}
                                            </p>
                                        </div>

                                        <div
                                            className="release-notes__description"
                                            dangerouslySetInnerHTML={{__html: release.description}}>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
                : null
            }
        </DefaultLayout>
    )
};

export default ReleaseNotes;
